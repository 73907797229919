import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { CloseIcon } from "../Helpers/svg";

export const ModalNav = ({ title, closeModal }) => (
  <Box
    sx={{
      p: "15px",
      borderBottom: "1px solid #0002",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Typography
      sx={{
        fontSize: "18px",
        fontWeight: 600,
      }}
    >
      {title}
    </Typography>

    <Box
      sx={{
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        background: "#000",
        cursor: "pointer",
        display: "grid",
        placeContent: "center",
      }}
      onClick={closeModal}
    >
      <CloseIcon fill="#fff" />
    </Box>
  </Box>
);

export default function LeftToRightModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {props?.openButton ? (
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          {props?.openButton}
        </Box>
      ) : (
        <Button onClick={handleOpen}>{props?.btnTitle || "Open modal"}</Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        keepMounted
      >
        <Slide
          direction="left"
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0%",
              right: "0px",
              width: {
                sm: props?.width || "70%",
                xs: "100%",
              },
              background: "#fff",
              boxShadow: 24,
              height: '100vh',
              overflowY: 'scroll'
            }}
          >
            <ModalNav title={props?.modalTitle} closeModal={handleClose} />
            {props?.modalBody ? (
              props?.modalBody
            ) : (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Text in a modal
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </Typography>
              </>
            )}
          </Box>
        </Slide>
      </Modal>
    </>
  );
}
