import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { GetHandlerForPaymentGateway } from "../../../Redux/RequestHandlers";
export default function POSCryptoSearchBoxes({
  icon,
  crypto,
  cryptoshortname,
  pricesymbol,
  handleActive,
  active,
  itemIndex,
  setTimer,
  setTotamount,
  setLoadingAmount,
  amount,
  currency,
  shouldContinue,
  setShouldContinue
}) {
  const [rate, setRate] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState(true);
  const dispatch = useDispatch();

  const handleClick = async () => {
    setTimer(15);

    setIsClicked(true);
    setError(true);
    handleActive(itemIndex);
    setLoadingAmount(true);
    try {
      const req = {
        url: "getRealTimePrice",
        action: "ACTION_GET_REAL_TIME_PRICE",
        params: {
          pricesymbol: pricesymbol,
          amount: amount,
          currency: currency

        },
      };
      const result = await dispatch(GetHandlerForPaymentGateway(req));
      const curr = currency.toLowerCase();
      setRate(Number(result?.data?.data?.price[pricesymbol][curr]).toFixed(2));
      setTotamount(
        Number(amount / result?.data?.data?.price[pricesymbol][curr])
      );
      setLoadingAmount(false);
      setIsClicked(false);
      if (!result?.success) setError(false);
      if (!result?.data?.success) setShouldContinue(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const intervalId = active && setInterval(handleClick, 15000);

    if (intervalId && !shouldContinue) clearInterval(intervalId);
    // Clean up the interval when the component unmounts or when the box becomes inactive
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, itemIndex, shouldContinue]);

  return (
    <Box
      key={itemIndex + "fd"}
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        borderRadius: "34.5px",
        border:
          active && error ? "none" : "0.75px solid var(--Neutral-300, #EFF0F6)",
        background: active && error ? "#495ECA" : "#FFF",
        color: active && error ? "#FFF" : "#170F49",
        boxShadow:
          active && error
            ? "0px 4px 12px rgba(73, 94, 202, 0.4)"
            : "0px 1.5px 4.5px 0px rgba(73, 94, 202, 0.18)",
        p: "8.25px 21.75px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: active && error ? "space-between" : "center",
        minWidth: "145px",
        textDecoration: "none",
        transition: "background 0.3s, color 0.3s, box-shadow 0.3s",
        position: "relative",
      }}
    >
      {isClicked ? (
        <Box
          sx={{
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#fff" }} size="1.7rem" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "7.5px",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* {icon} */}
            <Box
              component="img"
              src={icon}
              sx={{ height: "24px!important", width: "auto" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  // color: "#170F49",
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                {crypto}
              </Typography>
              <Typography
                sx={{
                  color: active && error ? "#FFF" : "#A4A8AB",
                  fontFamily: "SF Pro Display",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {cryptoshortname}
              </Typography>
            </Box>
          </Box>
          {active && error && (
            <Typography
              sx={{
                // color: "#495ECA",
                fontFamily: "SF Pro Display",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textAlign: "right",
              }}
            >
              {rate} {currency}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
